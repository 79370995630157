import { createSlice } from '@reduxjs/toolkit';

interface initialStateInterface {
  choices: Array<object> | null;
  retailChoices: Array<object> | null;
  orgProducts: Array<object> | null;
  userDetails: object | null;
}

const initialState: initialStateInterface = {
  choices: null,
  retailChoices: null,
  orgProducts: null,
  userDetails: null,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setChoices: (state, action) => {
      state.choices = action.payload.choices;
    },
    setRetailChoices: (state, action) => {
      state.retailChoices = action.payload.choices;
    },
    setOrgProducts: (state, action) => {
      state.orgProducts = action.payload.orgProducts;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload.userDetails;
    },
    clearData: (state) => {
      return { ...initialState };
    },
  },
});

export const dataActions = dataSlice.actions;
export default dataSlice.reducer;
