import { Image, notification } from 'antd';
import successNotificationIcon from '../../../assets/icons/success-notification.svg';
notification.config({
  maxCount: 1,
  duration: 2.5,
});

export const successNotification = (title: string, description?: string) => {
  notification.success({
    message: title,
    description: description,
    placement: 'bottom',
    icon: <Image src={successNotificationIcon} preview={false} width={21} />,
  });
};

export const errorNotification = (title: string, description?: string) => {
  notification.error({
    placement: 'bottom',
    message: title,
    description: description,
    // duration: 1.5,
  });
};

export const alertNotification = (title: string, description?: string) => {
  notification.info({
    message: title,
    description: description,
    placement: 'bottom',
    // duration: 1.5,
  });
};
