import { CloseOutlined } from '@ant-design/icons';

interface Props {
  param: string;
  name: string;
  value: string | number;
  removeFilter: (param: string, value: string | number) => void;
}

const FilterTags: React.FC<Props> = ({ param, value, name, removeFilter }) => {
  return (
    <div className="filter-tags">
      <span className="filter">{name}</span>
      <span
        className="remove-icon"
        onClick={() => {
          removeFilter(param, value);
        }}
      >
        <CloseOutlined size={8} className="cta" />
      </span>
    </div>
  );
};

export default FilterTags;
