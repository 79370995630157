import { Result, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import '../../../src/RetailApp.scss';
import '../../../src/retail-antd-custom.scss';
import retailApi from '../../api/retailApi';
import Footer from '../../components/Retail/Footer/Footer';
import Header from '../../components/Retail/Header/Header';
import { errorNotification } from '../../components/UI/Toast/Toast';
import Router from '../../router/Router';
import { dataActions } from '../../store/data';
import { errorHandler, getLocalData, getRetailAccessToken, setLocalData } from '../../utils/helper';

const Layout: React.FC = (props: any) => {
  const referrer = document.referrer,
    location = useLocation(),
    [loading, setLoading] = useState(true),
    dispatch = useDispatch(),
    [validReferrer, setValidReferrer] = useState(false),
    // partner = window.location.pathname?.includes('retail-partner') ? window.location.pathname.split('/')[2] : false,
    [partner, setPartner] = useState(undefined as any),
    navigate = useNavigate(),
    getChoices = () => {
      retailApi.user
        .getChoices({
          ...(partner ? { slug: partner } : {}),
        })
        .then((response) => {
          console.log(response);
          if (response?.data?.content_choices?.organisation) {
            setValidReferrer(true);
            setLoading(false);
          }
          dispatch(dataActions.setRetailChoices({ choices: response?.data }));
        })
        .catch((err: any) => { });
    },
    getPartnerDetails = () => {
      retailApi.partner.getPartnerDetails({ slug: partner })
        .then((res) => {
          if (res?.data?.referral === null || res?.data?.referral?.length === 0 || res?.data?.referral?.includes(referrer)) {
            setValidReferrer(true);
            setLoading(false);
          }
          else {
            setValidReferrer(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorHandler(err?.response?.data);
        })
    };
  // { partner } = useParams();


  // useEffect(() => {
  //   // let partner = window.location.pathname.split('/')[1];
  //   console.log(choices, partner);
  //   if (partner) {
  //     getPartnerDetails(partner)
  //   }
  //   else if (choices) {
  //     console.log(choices)
  //     if (choices?.organisation_uuid) {
  //       setValidReferrer(true);
  //       setLoading(false);
  //     }
  //     if (!choices?.organisation_uuid) {
  //       setValidReferrer(false);
  //       setLoading(false);
  //     }
  //   }
  //   else {
  //     console.log('thiss')
  //   }
  // }, [partner, choices]);

  useEffect(() => {
    const routePattern = "/retail-partner/:partner/*";
    const match = matchPath(routePattern, location.pathname);

    if (match) {
      const partner = match?.params?.partner;
      setPartner(partner);
      // setValidReferrer(false)
      console.log('Partner', partner);
    }
    else {
      setTimeout(() => {
        if (!getRetailAccessToken()) {
          errorNotification('Please login to access the page');
          setLocalData('targetUrl', window.location.href.split('/').slice(3, 0).join('/'));
          navigate('/');
        }
        // setValidReferrer(false);
        // setLoading(false);
      }, 2000)

    }
  }, [location]);

  useEffect(() => {
    if (partner) {
      getPartnerDetails();
    }
    else {
      if (getRetailAccessToken()) {
        setLoading(false);
        setValidReferrer(true);
        // getChoices();
      }
    }
  }, [partner]);

  // useEffect(() => {
  // }, [partner])

  return (
    <div>
      <Header />
      {loading &&
        <Result
          icon={<Spin />}
          style={{ marginTop: '20vh', marginBottom: '20vh' }}
          title="Checking Eligiblity"
          subTitle={`Checking if you are eligible to avail these offers.`}
        />
      }
      {!validReferrer && !loading &&
        <Result
          status="warning"
          style={{ marginTop: '20vh', marginBottom: '20vh' }}
          title="Invalid Referrer"
          subTitle={`You are not elgible for these offers.`}
        />
      }
      {validReferrer && !loading &&
        <Router />
      }
      {!getLocalData('isPhoneApp') && <Footer />}
    </div>
  );
};

export default Layout;
