import { Col, Form, Input, Modal, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import ReactGA from "react-ga4";
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import '../../../RetailApp.scss';
import retailApi from '../../../api/retailApi';
import { COUNTRY_CODE } from '../../../constants/config';
import { authActions } from '../../../store/auth';
import { deleteLocalData, errorHandler } from '../../../utils/helper';
import { identify, setUserProperties } from '../../../utils/trackers';
import { errorNotification, successNotification } from '../../UI/Toast/Toast';
import Button from '../UI/Button/Button';
import './_loginModal.scss';

interface Props {
    isModalOpen?: boolean;
    cancelHandler?: () => void;
    confirmHandler?: () => void;
    data?: any;
}


const LoginModal: React.FC<Props> = ({ isModalOpen, cancelHandler, confirmHandler, data }) => {
    const [errorMessage, setErrorMessage] = useState(''),
        [successMessage, setSuccessMessage] = useState(''),
        [buttonLoading, setButtonLoading] = useState(false),
        [activeTab, setActiveTab] = useState('request'),
        primaryColor = useSelector((state: any) => state.theme.primaryColor),
        [disabledSave, setDisabledSave] = useState(true),
        [otpAttempteLeft, setOtpAttemptsLeft] = useState(parseInt(localStorage.getItem('otp-attempts') || '0')),
        [otpTimeout, setOtpTimeout] = useState(parseInt(localStorage.getItem('otp-timeout') || '0')),
        [otpError, setOtpError] = useState(false),
        [otp, setOtp] = useState(''),
        [verifyForm] = Form.useForm(),
        dispatch = useDispatch(),
        [requestForm] = Form.useForm(),
        updateOTP = (otp: string) => {
            setOtpError(false);
            setOtp(otp);
        },
        resendOTP = () => {
            const data = {
                mobile: requestForm.getFieldValue('username'),
                intent: 'signup_login',
            };
            retailApi.user
                .requestOTP(data)
                .then((response) => {
                    successNotification(response?.message);
                    setOtp('');
                    setOtpError(false);
                    localStorage.setItem(
                        'otp-timeout',
                        moment().add(response?.data?.expire_time_in_minutes, 'minutes').valueOf().toString()
                    );
                    localStorage.setItem('otp-attempts', response?.data?.remaining_attempts);
                    setOtpTimeout(
                        moment.now() < moment().add(response?.data?.expire_time_in_minutes, 'minutes').valueOf()
                            ? moment().add(response?.data?.expire_time_in_minutes, 'minutes').valueOf()
                            : 0
                    );
                    setOtpAttemptsLeft(response?.data?.remaining_attempts);
                })
                .catch((error) => {
                    errorNotification(error.response?.data?.mobile?.[0] || 'Something went wrong');
                });
        },
        onOTPVerify = () => {
            if (otp.length === 6) {
                setButtonLoading(true);
                retailApi.user
                    .verifyOTP({
                        otp: otp,
                        phone: requestForm.getFieldValue('username'),
                    })
                    .then((response) => {
                        var script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.innerHTML = `window.pagesense = window.pagesense || [];
                              window.pagesense.push(['identifyUser', '${requestForm.getFieldValue('username')}']);`
                        document.head.appendChild(script);
                        identify(requestForm.getFieldValue('username'));
                        setUserProperties({
                            $name: requestForm.getFieldValue('username'),
                            $phone: requestForm.getFieldValue('username'),
                        });

                        setErrorMessage('');
                        setOtpError(false);
                        deleteLocalData('otp-timeout');
                        deleteLocalData('otp-attempts');
                        setButtonLoading(false);
                        console.log(response);
                        dispatch(authActions.retailLogin({ accessToken: response.access_token, refreshToken: response.refresh_token, phone: requestForm?.getFieldValue('username') }));
                        ReactGA.event('login', { method: 'OTP' });
                        confirmHandler && confirmHandler();
                    })
                    .catch((error) => {
                        errorNotification(error.response?.data?.otp?.[0])
                        setButtonLoading(false);
                        setOtpError(true);
                        errorHandler(error?.response?.data);
                    });
            }
        },
        onCancel = () => {
            setOtp('');
            verifyForm.resetFields(['username']);
            cancelHandler && cancelHandler();
            setActiveTab('request');
        }

    const handleFormChange = () => {
        const hasErrors = requestForm.getFieldsError().some(({ errors }) => errors.length);
        setDisabledSave(hasErrors);
    };

    const onOTPRequest = () => {
        requestForm
            .validateFields()
            .then((values) => {
                const data = {
                    mobile: values.username,
                    intent: 'signup_login',
                };
                setButtonLoading(true);
                retailApi.user
                    .requestOTP(data)
                    .then((response) => {
                        setActiveTab('verify')
                        localStorage.setItem(
                            'otp-timeout',
                            moment().add(response.data.expire_time_in_minutes, 'minutes').valueOf().toString()
                        );
                        localStorage.setItem('otp-attempts', response.data.remaining_attempts);
                        setButtonLoading(false);
                    })
                    .catch((error) => {
                        errorNotification(error.response?.data?.mobile?.[0] || 'Something went wrong');
                        setButtonLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const requestOTP = (
        <div className='content'>
            <div className='subheading'>Enter your phone number to continue</div>
            <Form
                name="request-otp"
                form={requestForm}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFieldsChange={handleFormChange}
                initialValues={{ remember: true }}
                autoComplete="off"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onOTPRequest();
                    }
                }}
            >
                <Form.Item
                    name="username"
                    label="Phone"
                    className='mt-16'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    validateFirst={true}
                    validateTrigger="onChange"
                    normalize={(value: any) => value.trim()}
                    rules={[
                        { required: true, message: 'Please input your phone number!' },
                        { pattern: RegExp('[6-9]{1}[0-9]{9}'), message: 'Please enter valid mobile number' },
                        { len: 10, message: 'Phone number should be 10 character long' },
                    ]}
                >
                    <Input addonBefore={COUNTRY_CODE} className="input-field" placeholder="Phone" required pattern="\d*" />
                </Form.Item>
            </Form>
            <Button type='primary' block className="mt-32" onClick={onOTPRequest} loading={buttonLoading} disabled={disabledSave}>
                Request OTP
            </Button>
        </div>
    );

    const verifyOTP = (
        <div className='content'>
            <div className='subheading text-center'>Enter the OTP sent to {requestForm.getFieldValue('username')}</div>
            <Form
                name="enter-otp"
                form={verifyForm}
                className="otp-form"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onOTPVerify();
                    }
                }}
            >
                <Row justify="center" className='mt-16'>
                    <OtpInput
                        value={otp}
                        onChange={updateOTP}
                        numInputs={6}
                        separator={<span>&nbsp;&nbsp;</span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                            outline: 'none',
                            width: '36px',
                            height: '36px',
                            borderRadius: 4,
                            color: 'black',
                            border: '0.5px solid black',
                        }}
                        focusStyle={{
                            border: `1px solid ${primaryColor}`,
                        }}
                        className="otp-input"
                        errorStyle={{ color: '#C83532', border: '0.5px solid #C83532' }}
                        hasErrored={otpError}
                    />
                </Row>
                <Row justify="center" className='mt-32'>
                    <Col span={24}>
                        <Button type='primary' block onClick={onOTPVerify} loading={buttonLoading} disabled={otpError}>
                            Verify
                        </Button>
                    </Col>
                </Row>
            </Form>
            {otpAttempteLeft != 0 && (<>
                <div className='text-center mt-16'>
                    Didn't receive the verification code?
                </div>
                <div className="cta text-center mt-4" onClick={() => resendOTP()}>
                    Resend OTP
                </div>
            </>
            )}
            {/* {otpAttempteLeft == 0 &&
                    <Col span={24}>
                        <Row justify="center">
                            <div className='no-code'>
                                Try requesting new OTP after some time
                            </div>
                        </Row>
                    </Col>
                } */}
        </div >
    );


    return (
        <div className='retail-layout'>
            <Modal
                visible={isModalOpen}
                onCancel={onCancel}
                className='retail-login-modal'
                footer={null}
                closable={false}
            >
                {/* {verifyOTP} */}
                {activeTab === 'request' ? requestOTP : verifyOTP}
            </Modal>
        </div>
    )
}

export default LoginModal;
