import { useState } from 'react';
// import FAB from '../../../assets/icons/FAB.svg';
import { ContactModal } from '../../Modals/CustomModal';
import { SecondaryButton } from '../Button/Button';
import './_contact-card.scss';

const FAB = (showContactModal: any) => {
  return <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg" className='float'>
    <g filter="url(#filter0_d_9851_62818)" onClick={showContactModal} className='float-1'>
      <path d="M88 20C88 17.7909 86.2091 16 84 16H52C32.1177 16 16 32.1178 16 52C16 71.8823 32.1177 88 52 88C71.8822 88 88 71.8823 88 52V20Z" fill="#11A387" />
    </g>
    <path d="M60 49H58C57.9992 48.2046 57.6829 47.442 57.1204 46.8796C56.558 46.3171 55.7954 46.0008 55 46V44C56.3256 44.0016 57.5964 44.5289 58.5338 45.4662C59.4711 46.4036 59.9984 47.6744 60 49Z" fill="white" />
    <path d="M64.001 49H62.001C61.9989 47.1441 61.2607 45.3649 59.9484 44.0526C58.6361 42.7403 56.8569 42.0021 55.001 42V40C57.3871 40.0026 59.6748 40.9517 61.3621 42.639C63.0493 44.3262 63.9984 46.6139 64.001 49ZM62.001 65H61.831C42.181 63.87 39.391 47.29 39.001 42.23C38.9401 41.4367 39.1967 40.6518 39.7145 40.0477C40.2323 39.4437 40.9688 39.07 41.762 39.009C41.8413 39.003 41.921 39 42.001 39H47.271C47.6716 38.9996 48.063 39.1195 48.3946 39.3442C48.7263 39.5688 48.9828 39.8879 49.131 40.26L50.651 44C50.7974 44.3635 50.8337 44.7621 50.7555 45.1461C50.6772 45.5301 50.4879 45.8827 50.211 46.16L48.081 48.31C48.4124 50.2013 49.3174 51.945 50.6733 53.3045C52.0293 54.664 53.7706 55.5737 55.661 55.91L57.831 53.76C58.1125 53.4862 58.4684 53.3013 58.8543 53.2283C59.2402 53.1554 59.639 53.1977 60.001 53.35L63.771 54.86C64.1375 55.0129 64.4502 55.2714 64.6693 55.6027C64.8883 55.9339 65.0038 56.3229 65.001 56.72V62C65.001 62.7956 64.6849 63.5587 64.1223 64.1213C63.5597 64.6839 62.7967 65 62.001 65ZM42.001 41C41.8697 40.9996 41.7396 41.0251 41.6181 41.075C41.4966 41.1249 41.3862 41.1982 41.293 41.2908C41.1999 41.3834 41.1259 41.4934 41.0753 41.6145C41.0247 41.7357 40.9984 41.8657 40.998 41.997C40.998 42.025 40.999 42.0527 41.001 42.08C41.461 48 44.411 62 61.941 63C62.2057 63.0159 62.4658 62.926 62.6642 62.7501C62.8626 62.5742 62.983 62.3267 62.999 62.062L63.001 62V56.72L59.231 55.21L56.361 58.06L55.881 58C47.181 56.91 46.001 48.21 46.001 48.12L45.941 47.64L48.781 44.77L47.281 41H42.001Z" fill="white" />
    <defs>
      <filter id="filter0_d_9851_62818" x="0" y="0" width="104" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9851_62818" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9851_62818" result="shape" />
      </filter>
    </defs>
  </svg>
}

const helplineIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.305 22.5L12 21.75L15 16.5H19.5C19.8978 16.5 20.2794 16.342 20.5607 16.0607C20.842 15.7794 21 15.3978 21 15V6C21 5.60218 20.842 5.22064 20.5607 4.93934C20.2794 4.65804 19.8978 4.5 19.5 4.5H4.5C4.10218 4.5 3.72064 4.65804 3.43934 4.93934C3.15804 5.22064 3 5.60218 3 6V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H11.25V18H4.5C3.70435 18 2.94129 17.6839 2.37868 17.1213C1.81607 16.5587 1.5 15.7956 1.5 15V6C1.5 5.20435 1.81607 4.44129 2.37868 3.87868C2.94129 3.31607 3.70435 3 4.5 3H19.5C20.2956 3 21.0587 3.31607 21.6213 3.87868C22.1839 4.44129 22.5 5.20435 22.5 6V15C22.5 15.7956 22.1839 16.5587 21.6213 17.1213C21.0587 17.6839 20.2956 18 19.5 18H15.87L13.305 22.5Z" fill="#11A387" />
  <path d="M6 7.5H18V9H6V7.5ZM6 12H13.5V13.5H6V12Z" fill="#11A387" />
</svg>


interface Props {
  relationship_managers: any;
  activeMode: string;
}

const ContactCardMobile: React.FC<Props> = ({ relationship_managers, activeMode }) => {
  // const claimsHelplineNumber = '+919021323456'
  const [contactModal, showContactModal] = useState(false);
  return (<div className='mobile-contact-card'>
    {/* {FAB(() => showContactModal(true))} */}
    <div className='title'>Need Help?</div>
    <div className='subtitle'>Reach out instantly to our support team with your questions</div>
    <div className='cta'>
      <SecondaryButton onClick={() => showContactModal(true)}>
        HELPLINE
      </SecondaryButton>
    </div>
    <ContactModal
      isModalVisible={contactModal}
      cancelHandler={() => showContactModal(false)}
      data={
        {
          ...(activeMode === 'employer' && {
            relationship_manager: {
              full_name: relationship_managers?.policy_manager?.full_name,
              phone: relationship_managers?.policy_manager?.phone,
              email: relationship_managers?.policy_manager?.email,
            }
          }),
          claim_manager: {
            full_name: relationship_managers?.claim_manager?.full_name,
            phone: relationship_managers?.claim_manager?.phone,
            email: relationship_managers?.claim_manager?.email,
          },
        }
      }
    />
  </div>
  );
};

export default ContactCardMobile;
