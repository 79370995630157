import { NavLink } from 'react-router-dom';
import './_navbars.scss';

interface Props {
  to?: string;
  children?: any;
  className?: string;
}

const NavbarLink: React.FC<Props> = ({ to, children, className }) => {
  if (to) {
    return (
      <NavLink to={to} className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}>
        <div className="navbar-item">{children}</div>
      </NavLink>
    );
  } else {
    return (
      <a className={`nav-link ${className}`}>
        <div className="navbar-item">{children}</div>
      </a>
    )
  }
};

export default NavbarLink;
