import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import ContactCardMobile from './ContactCardMobile';
import ContactCardWeb from './ContactCardWeb';

const ContactCard: React.FC = () => {
  const relationship_managers = useSelector((state: any) => state.auth.relationship_managers),
    activeMode = useSelector((state: any) => state.auth.activeMode);
  return (
    <Row>
      <Col span={24}>
        <ContactCardMobile relationship_managers={relationship_managers} activeMode={activeMode} />
      </Col>
      <Col xxl={24} xl={24} lg={24} md={0} sm={0} xs={0}>
        <ContactCardWeb relationship_managers={relationship_managers} activeMode={activeMode} />
      </Col>
    </Row>
  );
};

export default ContactCard;
