import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUHIActive: false,
};

const uhiSlice = createSlice({
  name: 'uhi',
  initialState,
  reducers: {
    setUHI: (state, action) => {
      state.isUHIActive = action.payload;
    },
  },
});

export const UHIActions = uhiSlice.actions;
export default uhiSlice.reducer;
