import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  primaryColor: '',
  logoUrl: '',
  loginOptions: {},
  isDark: false,
  brandName: '',
  brokerName: '',
  description: '',
  supportPhone: '',
  supportEmail: '',
};

const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.primaryColor = action.payload.primaryColor;
      state.logoUrl = action.payload.logoUrl;
      state.loginOptions = action.payload.loginOptions;
      state.isDark = action.payload.isDark;
      state.brandName = action.payload.brandName;
      state.brokerName = action.payload.brokerName;
      state.description = action.payload.description;
      state.supportPhone = action.payload.supportPhone;
      state.supportEmail = action.payload.supportEmail;
    },
  },
});

export const themeActions = theme.actions;

export default theme.reducer;
