import { Image, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../UI/Button/Button';

interface Props {
  title: string | undefined;
  subtitle: string | undefined;
  image: string | undefined;
}

const ErrorScreen: React.FC<Props> = ({ title, subtitle, image }) => {
  const navigate = useNavigate();

  return (
    <Row justify="center" align="middle" className="center-height">
      <div className="error-screen">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <Image src={image} width={640} preview={false} className="image" />
        <Row justify="center" align="middle" className="cta">
          <PrimaryButton onClick={() => navigate(-1)}>BACK TO PREVIOUS SCREEN</PrimaryButton>
        </Row>
      </div>
    </Row>
  );
};

export default ErrorScreen;
