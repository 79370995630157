import { Col, Image, Row } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import hLogo from '../../assets/images/Hlogo.svg';
import logo from '../../assets/images/logo.svg';
import Router from '../../router/Router';
import { deleteLocalData, getLocalData } from '../../utils/helper';
import './_upgradation.scss';

const UpgradationLayout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (getLocalData('upgradetoken')) {
        deleteLocalData('upgradetoken');
      }
    };
  }, []);

  return (
    <div className="uhi-upgradation">
      <Router />
    </div>
  );
};

export default UpgradationLayout;
