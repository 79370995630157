import { Col, Form, Input, InputNumber, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { NavLink, useNavigate, useOutletContext } from 'react-router-dom';
import { LoginOutletContext } from './Login';
import PrimaryButton from '../../../components/UI/Button/Button';
import { useEffect, useState } from 'react';
import LoginOptions from './LoginOptions';
import api from '../../../api/api';
import { deleteLocalData, errorHandler } from '../../../utils/helper';
import { trackData } from '../../../utils/trackers';
import { useDispatch, useSelector } from 'react-redux';
import { EyeInvisibleFilled, EyeFilled, EditOutlined } from '@ant-design/icons';
import { authActions } from '../../../store/auth';
import moment from 'moment';
import { errorNotification, successNotification } from '../../../components/UI/Toast/Toast';

interface Props {
  type: 'phone' | 'email';
}

const CheckSSO: React.FC<Props> = ({ type }) => {
  const [form] = useForm(),
    [loading, setLoading] = useState<boolean>(false),
    dispatch = useDispatch(),
    {google, microsoft} = useSelector((state: any) => state?.theme?.loginOptions),
    context = useOutletContext<LoginOutletContext>(),
    [otpError, setOtpError] = useState(false),
    [otpTimeout, setOtpTimeout] = useState(parseInt(localStorage.getItem('otp-timeout') || '0')),
    [otpAttemptsLeft, setOtpAttemptsLeft] = useState(parseInt(localStorage.getItem('otp-attempts') || '0')),
    resendOTPHandler = () => {
      const data = {
        mobile: context?.phone,
        intent: 'login',
      };
      api.profile
        .requestOTP(data)
        .then((response) => {
          successNotification('OTP has been sent again');
          setOtpError(false);
          localStorage.setItem(
            'otp-timeout',
            moment().add(response?.data?.expire_time_in_minutes, 'minutes').valueOf().toString()
          );
          localStorage.setItem('otp-attempts', response?.data?.remaining_attempts);
          setOtpTimeout(
            moment.now() < moment().add(response?.data?.expire_time_in_minutes, 'minutes').valueOf()
              ? moment().add(response?.data?.expire_time_in_minutes, 'minutes').valueOf()
              : 0
          );
          setOtpAttemptsLeft(response?.data?.remaining_attempts);
        })
        .catch((error) => {
          errorNotification(error.response?.data?.mobile?.[0] || 'Something went wrong');
        });
    },
    sendOTPHandler = (phone: string) => {
      const data = {
        mobile: phone,
        intent: 'login',
      };
      setLoading(true);
      api.profile
        .requestOTP(data)
        .then((response) => {
          localStorage.setItem(
            'otp-timeout',
            moment().add(response.data.expire_time_in_minutes, 'minutes').valueOf().toString()
          );
          localStorage.setItem('otp-attempts', response.data.remaining_attempts);
          setLoading(false);
        })
        .catch((error) => {
          errorHandler(error?.response?.data);
          setLoading(false);
        });
    },
    checkSSO = () => {
      form
        .validateFields()
        .then((val) => {
          setLoading(true);
          const params = type === 'email' ? { email: val?.email } : { phone: val?.phone };
          api.profile
            .checkSSO(params)
            .then((res) => {
              context?.setSso(res?.sso_enabled);
              if (res?.sso_login_url) {
                context?.SetSsoURL(res?.sso_login_url);
              }
              type === 'email' ? context?.setEmail(val?.email) : context?.setPhone(val?.phone);
              if (!res?.sso_enabled && type === 'phone') {
                sendOTPHandler(val?.phone);
              }
              setLoading(false);
            })
            .catch((err) => {
              errorHandler(err?.response?.data);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err)
        });
    },
    emailLoginHandler = () => {
      form.validateFields()
      .then((values) => {
        const data = {
          username: values.email,
          password: values.password,
          grant_type: 'password',
          dashboard_type: 'external',
        };
        setLoading(true);
        api.profile
          .login(data)
          .then((res) => {
            setLoading(false);
            dispatch(authActions.login({ accessToken: res.access_token, refreshToken: res.refresh_token }));
            trackData('Login', { method: 'Password' });
          })
          .catch((error) => {
            errorHandler(error?.response?.data);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err)
      });
    },
    phoneLoginHandler = () => {
      form.validateFields()
      .then((values) => {
        api.profile
          .loginWithOTP({
            otp: values?.otp,
            intent: 'login',
            phone: values?.phone,
            // client_id: CLIENT_ID,
            dashboard_type: 'external',
          })
          .then((response) => {
            setOtpError(false);
            deleteLocalData('otp-timeout');
            deleteLocalData('otp-attempts');
            dispatch(authActions.login({ accessToken: response.access_token, refreshToken: response.refresh_token }));
            trackData('Login', { method: 'OTP' });
          })
          .catch((error) => {
            setOtpError(true);
            errorHandler(error?.response?.data);
          });
      })
      .catch((err) => {
        console.log(err)
      });
    },
    loginHandler = () => {
      type === 'email' ? emailLoginHandler() : phoneLoginHandler();
    },
    resetSSO = () => {
      form.setFieldsValue({
        password: '',
        otp: '',
      });
      context?.resetSSO();
    };

  useEffect(() => {
    form.setFieldsValue({
      email: context?.email,
      phone: context?.phone,
    });
    context?.resetSSO();
  }, []);

  return (
    <>
      <Form
        name="login"
        form={form}
        className="login-form"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (context?.sso === true) {
              window.location.href = context?.ssoURL;
            } else if (context?.sso === false) {
              type === 'email' ? emailLoginHandler() : phoneLoginHandler();
            } else {
              checkSSO();
            }
          }
        }}
      >
        {type === 'email' && (
          <Form.Item
            name="email"
            label="Email"
            className={`login-form-item`}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            validateFirst={true}
            validateTrigger="onBlur"
            normalize={(value: any) => value.trim()}
            rules={[
              { required: type === 'email', message: 'Please enter your email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input
              className={(context?.sso === false || context?.sso) && 'editable-input'}
              placeholder="Email"
              disabled={context?.sso !== undefined}
              addonAfter={(context?.sso === false || context?.sso) && <EditOutlined onClick={resetSSO} />}
            />
          </Form.Item>
        )}

        {type === 'email' && context?.sso === false && (
          <>
            <Form.Item
              name="password"
              label="Password"
              className="login-form-item"
              validateFirst={true}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateTrigger="onChange"
              rules={[{ required: type === 'email' && context?.sso === false, message: 'Please enter your password!' }]}
            >
              <Input.Password
                bordered={false}
                type="password"
                placeholder="Password"
                autoComplete="on"
                iconRender={(visible) => (!visible ? <EyeInvisibleFilled /> : <EyeFilled />)}
              />
            </Form.Item>
            <Row justify="end" align="middle" style={{ marginTop: '-20px' }}>
              <Col>
                <NavLink to="request-password-reset" className="cta-text text">
                  Forgot Password
                </NavLink>
              </Col>
            </Row>
          </>
        )}

        {type === 'phone' && (
          <Form.Item
            name="phone"
            label="Phone"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            validateFirst={true}
            validateTrigger="onChange"
            normalize={(value: any) => value.trim()}
            rules={[
              { required: type === 'phone', message: 'Please input your phone number!' },
              { pattern: RegExp('[6-9]{1}[0-9]{9}'), message: 'Please enter valid mobile number' },
              { len: 10, message: 'Phone number should be 10 character long' },
            ]}
          >
            <Input
              className={(context?.sso === false || context?.sso) && 'editable-input'}
              placeholder="Phone"
              pattern="\d*"
              disabled={context?.sso !== undefined}
              addonAfter={(context?.sso === false || context?.sso) && <EditOutlined onClick={resetSSO} />}
            />
          </Form.Item>
        )}

        {type === 'phone' && context?.sso === false && (
          <>
            <Form.Item
              name="otp"
              label="OTP"
              className="login-form-item"
              validateFirst={true}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateTrigger="onChange"
              rules={[
                {
                  required: type === 'phone' && context?.sso === false,
                  message: "Please enter the OTP you've received!",
                },
                { len: 6, message: 'OPT should be 6 digits long' },
              ]}
            >
              <Input />
            </Form.Item>
            <Row justify="end" align="middle" style={{ marginTop: '-20px' }}>
              <Col>
                <div className="cta" onClick={() => resendOTPHandler()}>
                  Resend OTP
                </div>
              </Col>
            </Row>
          </>
        )}

        {context?.sso === undefined && (
          <div className="login-cta">
            <PrimaryButton className="login-screen-btn" loading={loading} onClick={checkSSO}>
              Next
            </PrimaryButton>
          </div>
        )}
        {context?.sso === true && (
          <a href={context?.ssoURL} className="login-cta" target="_blank">
            <PrimaryButton className="login-screen-btn" loading={loading}>
              Log in Using SSO
            </PrimaryButton>
          </a>
        )}
        {context?.sso === false && (
          <div className="login-cta">
            <PrimaryButton className="login-screen-btn" loading={loading} onClick={loginHandler}>
              Log in
            </PrimaryButton>
          </div>
        )}
      </Form>
      <LoginOptions
        google={context?.sso === false && google}
        microsoft={context?.sso === false && microsoft}
        resetSSO={context?.resetSSO}
        phone={type !== 'phone'}
        email={type !== 'email'}
      />
    </>
  );
};

export default CheckSSO;
