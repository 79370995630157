export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || (window as any).env.GOOGLE_CLIENT_ID;
export const CASHFREE_URL = process.env.REACT_APP_CASHFREE_URL || (window as any).env.CASHFREE_URL;
export const GOOGLE_CLIENT_SECRET =
  process.env.REACT_APP_GOOGLE_CLIENT_SECRET || (window as any).env.GOOGLE_CLIENT_SECRET;
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID || (window as any).env.MICROSOFT_CLIENT_ID;
export const GOOGLE_ANALYTICS_TRACKING_CODE =
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE || (window as any).env.GOOGLE_ANALYTICS_TRACKING_CODE;
export const MIXPANEL = process.env.REACT_APP_MIXPANEL || (window as any).env.MIXPANEL;
export const WE_KEY = process.env.REACT_APP_WE || (window as any).env.WE_KEY;
export const MAIN_SITE_URL = 'https://www.healthysure.in';
export const BS_SITE_URL = 'https://www.bharatsure.com';
let API_URL = process.env.REACT_APP_API_URL || (window as any).env.API_URL;

let COUNTRY = 'IN';
let COUNTRY_CODES = { IN: '+91' } as any;
export const CURRENCY_SYMBOL = '₹';
export const LOCALE = 'en-IN';
export const COUNTRY_CODE = COUNTRY_CODES[COUNTRY];
// export const MICROSOFT_CLIENT_ID = "f8c7976f-3e93-482d-88a3-62a1133cbbc3"
export const FAMILY_MEMBER_PRIORITY = [
  'SELF',
  'SPOUSE',
  'CHILD',
  'DAUGHTER',
  'SON',
  'PARENT',
  'MOTHER',
  'FATHER',
  'PARENT_IN_LAW',
  'PARENTS_IN_LAW',
  'PARENTS/PARENTS_IN_LAW',
  'MOTHER_IN_LAW',
  'FATHER_IN_LAW',
];

//retail
export const MAIN_SITE_URL_ENQUIRY = 'https://healthysure.in/how-it-works/?source=HnW&campaign_id=';
export const BRAND_NAME = 'Healthysure';
export const RETAIL_GOOGLE_ANALYTICS_TRACKING_CODE = 'G-2MM8BS6NHL';
export const REACT_APP_GA = process.env.REACT_APP_GA || (window as any).env.REACT_APP_GA;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || (window as any).env.REACT_APP_MIXPANEL_TOKEN;
export const HDFCCOUPON = 'HDFCSURE';
export const DEXTERCOUPON = 'DEXTER2023';
let DEXTERSLUG = 'dexter-angels';

if (!API_URL) {
  if (window.location.hostname === 'localhost') {
    API_URL = '';
  } else {
    API_URL = window.location.protocol + '//' + window.location.hostname.replace('portal', 'api') + '/';
  }
}

export const DEFAULT_PAGE_SIZE = '10';

export { API_URL, DEXTERSLUG };
