import { Col, Image, Row } from 'antd';
import GoogleIcon from '../../../assets/icons/Google Login.svg';
import MicrosoftIcon from '../../../assets/icons/Microsoft login.svg';
import { useGoogleLogin } from '@react-oauth/google';
import api from '../../../api/api';
import { CLIENT_ID, MICROSOFT_CLIENT_ID } from '../../../constants/config';
import { trackData } from '../../../utils/trackers';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../store/auth';
import { errorHandler, getLocalData } from '../../../utils/helper';
import * as msal from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import { MailOutlined, MobileOutlined } from '@ant-design/icons';

interface Props {
  microsoft?: boolean;
  google?: boolean;
  email?: boolean;
  phone?: boolean;
  resetSSO?: VoidFunction;
}

const msalConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const LoginOptions: React.FC<Props> = ({ microsoft, google, email, phone, resetSSO }) => {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    isPhoneApp = getLocalData('isPhoneApp'),
    googleLogin = useGoogleLogin({
      onSuccess: (codeResponse) => {
        api.profile
          .googleLogin({
            code: codeResponse.code,
            client_id: CLIENT_ID,
            redirect_url: window.location.href.slice(0, -1),
          })
          .then((res) => {
            trackData('Login', { method: 'Google' });
            dispatch(
              authActions.login({ accessToken: res?.data?.access_token, refreshToken: res?.data?.refresh_token })
            );
            trackData('Login', { method: 'Google' });
          })
          .catch((error) => {
            errorHandler(error?.response?.data);
          });
      },
      onError: (error) => console.log(error),
      flow: 'auth-code',
    }),
    microsoftLogin = async () => {
      try {
        const loginResponse = await msalInstance.loginPopup({ scopes: ['User.Read'], prompt: 'select_account' });
        api.profile
          .microsoftLogin({
            code: loginResponse?.idToken,
            client_id: CLIENT_ID,
            redirect_url: window.location.href.slice(0, -1),
          })
          .then((res) => {
            trackData('Login', { method: 'Microsoft' });
            dispatch(
              authActions.login({ accessToken: res?.data?.access_token, refreshToken: res?.data?.refresh_token })
            );
          })
          .catch((error) => {
            errorHandler(error?.response?.data);
          });
      } catch (err) {}
    };

  return (
    <>
      <div className="text-between-line" style={{margin: '32px 0'}}>
        <span>or</span>
      </div>
      <Row gutter={[0, 16]} justify="center" align="middle">
        {email && (
          <Col span={24}>
            <div className="login-with" onClick={() => {resetSSO?.(); navigate('/')}}>
              <Row justify="center" align="middle" gutter={16}>
                <Col>
                  <MailOutlined height={16} width={16} />
                </Col>
                <Col>
                  <span className="login-with-text">Login with Email</span>
                </Col>
              </Row>
            </div>
          </Col>
        )}
        {phone && (
          <Col span={24}>
            <div className="login-with" onClick={() => {resetSSO?.(); navigate('login-with-phone')}}>
              <Row justify="center" align="middle" gutter={16}>
                <Col>
                  <MobileOutlined height={16} width={16} />
                </Col>
                <Col>
                  <span className="login-with-text">Login with Mobile OTP</span>
                </Col>
              </Row>
            </div>
          </Col>
        )}
        {!isPhoneApp && google && (
          <Col span={24}>
            <div className="login-with" onClick={() => googleLogin()}>
              <Row justify="center" align="middle" gutter={16}>
                <Col>
                  <Image src={GoogleIcon} alt="google" width={16} height={16} preview={false} />
                </Col>
                <Col>
                  <span className="login-with-text">Login with Google</span>
                </Col>
              </Row>
            </div>
          </Col>
        )}
        {!isPhoneApp && microsoft && (
          <Col span={24}>
            <div className="login-with" onClick={() => microsoftLogin()}>
              <Row justify="center" align="middle" gutter={16}>
                <Col>
                  <Image src={MicrosoftIcon} alt="microsoft" width={16} height={16} preview={false} />
                </Col>
                <Col>
                  <span className="login-with-text">Login with Microsoft</span>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default LoginOptions;
