import mixpanel from 'mixpanel-browser';
import { getLocalData } from './helper';
const mixpanelToken = getLocalData('mixpanel');
console.log(mixpanelToken);

declare global {
  interface Window {
    webengage: any;
  }
}

export const trackData = (name: string, data?: object) => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken);
    mixpanel.track(name, data);
  }
  if (window.webengage) {
    if (name === 'Logout') {
      window.webengage?.user.logout();
      return;
    }
    window.webengage?.track(name, data);
  }
};

export const startTimedEvent = (name: string) => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken);
    mixpanel.time_event(name);
  }
};

export const identify = (id?: string) => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken);
    if (id) {
      mixpanel.identify(id);
    }
  }
};

export const register = (data: any) => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken);
    mixpanel.register(data);
  }
};

export const setUserProperties = (data: any) => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken);
    mixpanel.people.set(data);
  }
};

export const trackLinks = (selector: string, action: string) => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken);
    mixpanel.track_links(selector, action);
  }
};
