import { SearchOutlined } from '@ant-design/icons';
import { Col, Divider, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import './_table.scss';
interface TFilter {
  id: number;
  value: string;
  title: string;
}

interface TCTA {
  title: string;
  render: JSX.Element;
}

interface Props {
  getParams: any;
  filters?: Array<TFilter>;
  customCTA?: Array<TCTA>;
  dataSource?: Array<any>;
  title?: string;
  showSearch: boolean;
  filterKey?: any;
  searchKey?: any;
  mobileViewActive?: boolean;
}

const TableCTA: React.FC<Props> = ({
  getParams,
  filters,
  customCTA,
  title,
  showSearch,
  filterKey,
  searchKey,
  mobileViewActive = true,
}) => {
  const [activeTab, setActiveTab] = useState(1),
    [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchTerm) {
        getParams({ full_name: searchTerm, offset: 0 });
      }
    }, 500);

    if (searchTerm === '') {
      getParams({ full_name: null, offset: 0 });
    }

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const searchHandler = (item: any) => {
    setSearchTerm(item);
  };

  const CTA = [
    ...(showSearch
      ? [
          {
            title: 'Search',
            render: (
              <Input
                allowClear
                className="search-input"
                placeholder="Search by employee name"
                suffix={<SearchOutlined />}
                onChange={(event) => {
                  event.persist();
                  setTimeout(() => {
                    searchHandler(event.target.value);
                  });
                }}
              />
            ),
          },
        ]
      : []),
  ];

  const onFilterClickHandler = (id: number, value: string) => {
    setActiveTab(id);
    getParams({
      [filterKey]: value,
      ...(searchKey && { [searchKey]: null }),
    });
  };

  return (
    <div className="table-cta">
      <Row align="middle">
        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
          <Row align="middle" justify="start" className="filters">
            <span className="table-filter-row">
              {filters &&
                filters?.map((filter, index) => {
                  return (
                    <React.Fragment key={index}>
                      <span
                        className={`${mobileViewActive === false ? '' : 'mobile-view'} ${
                          filter.id === activeTab ? 'content active' : 'content'
                        }`}
                        onClick={() => {
                          onFilterClickHandler(filter.id, filter.value);
                        }}
                      >
                        {filter.title}
                      </span>
                      {index !== filters?.length - 1 && (
                        <Divider type="vertical" className={`${mobileViewActive === false ? '' : 'show-on-web'}`} />
                      )}
                    </React.Fragment>
                  );
                })}
              {title && <span className="title">{title}</span>}
            </span>
          </Row>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Row align="middle" justify="end" gutter={[0, 8]}>
            {CTA.map((cta, index) => {
              return (
                <span key={index}>
                  <span className="content active">{cta.render}</span>
                  {(customCTA || index !== CTA.length - 1) && <Divider className="invisible-divider" type="vertical" />}
                </span>
              );
            })}
            {customCTA &&
              customCTA.map((cta, index) => {
                return (
                  <span key={index}>
                    <span className="content active">{cta.render}</span>
                    {index !== customCTA.length - 1 && <Divider className="invisible-divider" type="vertical" />}
                  </span>
                );
              })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TableCTA;