import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import retailApi from '../api/retailApi';
import {
  clearLocalData,
  getAccessToken,
  getLocalData,
  removeAxiosHeaders,
  setAccessToken,
  setAxiosHeaders,
  setLocalData,
  setRefreshToken,
  setRetailAccessToken,
  setRetailRefreshToken,
} from '../utils/helper';
import { identify, setUserProperties, trackData } from '../utils/trackers';

const isUserLoggedIn = () => {
  const accessToken = getAccessToken();
  return accessToken ? true : false;
};

const fetchCartCount = createAsyncThunk('auth/fetchCartCount', (data: any) => {
  return retailApi.cart
    .cartCount({
      slug: data?.slug,
      organisation_id: data?.organisation_id,
    })
    .then((res) => res.data.items_count);
});

interface TAuthState {
  id: number | null;
  uuid: number | null;
  member_id: number | string | null;
  member_uuid: string | null;
  status: string | null;
  isLoggedIn: boolean;
  roles: Array<string>;
  name: string;
  email: string;
  organisation_id: number | null;
  organisation_uuid: string | null;
  activeMode: string;
  relationship_managers: object;
  uhi: object;
  phone: string | null;
  cartCount: any;
}

const initialState: TAuthState = {
  uuid: null,
  id: null,
  member_id: null,
  member_uuid: null,
  isLoggedIn: isUserLoggedIn(),
  roles: [],
  status: null,
  name: '',
  email: '',
  organisation_id: null,
  organisation_uuid: null,
  activeMode: getLocalData('activeMode') || 'employee',
  relationship_managers: {},
  uhi: {},
  phone: null,
  cartCount: 0,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      setAccessToken(action.payload.accessToken);
      setRetailAccessToken(action.payload.accessToken);
      setRefreshToken(action.payload.refreshToken);
      setRetailRefreshToken(action.payload.refreshToken);
    },
    // retail
    retailLogin: (state, action) => {
      setRetailAccessToken(action.payload.accessToken);
      setRetailRefreshToken(action.payload.refreshToken);
      setAxiosHeaders();
      state.isLoggedIn = true;
      state.phone = action.payload?.phone;
    },

    retailLogout: (state) => {
      clearLocalData();
      removeAxiosHeaders();
      return { ...initialState, isLoggedIn: false };
    },

    userData: (state, action) => {
      identify(action.payload.id);
      setUserProperties({
        ID: action.payload?.id,
        'Member ID': action.payload?.member_id,
        $name: action.payload.name,
        $email: action.payload.email,
        $phone: action.payload.phone,
        'Organisation ID': action.payload.organisation_id,
        Roles: action.payload.roles,
      });
      state.uuid = action.payload?.uuid;
      state.id = action.payload?.id;
      state.member_id = action.payload?.member_id;
      state.member_uuid = action.payload?.member_uuid;
      state.roles = action.payload?.roles;
      state.name = action.payload?.name;
      state.email = action.payload?.email;
      state.status = action.payload?.status;
      state.organisation_id = action.payload?.organisation_id;
      state.organisation_uuid = action.payload?.organisation_uuid;
      state.relationship_managers = action.payload?.relationship_managers;
    },
    setUHI: (state, action) => {
      state.uhi = action.payload?.uhi;
    },
    setRoles: (state, action) => {
      state.roles = action.payload?.roles;
    },
    setActiveMode: (state, action) => {
      state.activeMode = action.payload;
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    changeActiveMode: (state, action) => {
      state.activeMode = action.payload;
      setLocalData('activeMode', action.payload);
    },

    logout: (state) => {
      clearLocalData();
      window.webengage?.user.logout();
      removeAxiosHeaders();
      trackData('Logout');
      return { ...initialState, isLoggedIn: false, activeMode: 'employee' };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCartCount.fulfilled, (state, action) => {
      state.cartCount = action.payload;
    });
  },
});

export const authActions = authSlice.actions;
export const updateCartCount = fetchCartCount;
export default authSlice.reducer;
