import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import dataReducer from './data';
import errorReducer from './error';
import flagsReducer from './flags';
import memberDataReducer from './memberdata';
import { default as realtimeReducer, default as themeReducer } from './theme';

import uhiReducer from './uhi';

const combinedReducer = combineReducers({
  auth: authReducer,
  uhi: uhiReducer,
  error: errorReducer,
  data: dataReducer,
  memberData: memberDataReducer,
  theme: themeReducer,
  flags: flagsReducer,
  realtime: realtimeReducer,

});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
