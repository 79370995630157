import { CloseOutlined } from '@ant-design/icons';
import { Col, Drawer, Image, Row } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import Navbar from '../Navbar/Navbar';
import './_header.scss';

interface Props {
  title: string;
}

const Header: React.FC<Props> = ({ title }) => {
  const [isVisible, setIsVisible] = useState(false),
    relationship_managers = useSelector((state: any) => state.auth.relationship_managers),
    activeMode = useSelector((state: any) => state.auth.activeMode),
    navigate = useNavigate(),
    headerTitle = (
      <Row align="middle" justify="space-between">
        <Col>
          <Image
            src={logo}
            height={50}
            onClick={() => {
              navigate('/');
            }}
          />
        </Col>
        <Col>
          <CloseOutlined
            onClick={() => {
              setIsVisible(false);
            }}
          />
        </Col>
      </Row>
    );

  return (
    <React.Fragment>
      <Drawer
        onClose={() => setIsVisible(false)}
        visible={isVisible}
        width="250px"
        getContainer={false}
        title={headerTitle}
        closable={false}
        className="nav-drawer"
      >
        <Navbar />
        {/* <Col span={24}> */}
      </Drawer>
      <div className="header-title">{title}</div>
    </React.Fragment>
  );
};

export default Header;
