import { FileDoneOutlined, GiftOutlined, HomeOutlined, SettingOutlined, TeamOutlined } from '@ant-design/icons';
import { Badge, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContactCard from '../UI/ContactCard/ContactCard';
import NavbarLink from './NavbarLink';
import { trackData } from '../../utils/trackers';

interface MenuProps {
  name: string;
  icon: any;
  link: string;
}

const Navbar: React.FC = () => {
  const activeMode = useSelector((state: any) => state.auth.activeMode),
    [activeMenu, setActiveMenu] = useState<Array<MenuProps>>([]),
    navigate = useNavigate(),
    userDetails = useSelector((state: any) => state.data.userDetails);
  useEffect(() => {
    if (activeMode === 'employer') {
      setActiveMenu(employerMenu);
    } else {
      setActiveMenu(employeeMenu);
    }
  }, [activeMode]);

  const employeeMenu = [
    {
      name: 'Home',
      icon: <HomeOutlined />,
      link: '/home',
    },
    {
      name: 'Claims',
      icon: <FileDoneOutlined />,
      link: '/claims',
    }
    // {
    //     name: 'Settings',
    //     icon: <SettingOutlined />,
    //     link: '/settings'
    // },
  ];

  const employerMenu = [
    {
      name: 'Dashboard',
      icon: <HomeOutlined />,
      link: '/dashboard',
    },
    {
      name: 'Members',
      icon: <TeamOutlined />,
      link: '/members',
    },
    {
      name: 'Claims',
      icon: <FileDoneOutlined />,
      link: '/claims',
    },
    {
      name: 'Settings',
      icon: <SettingOutlined />,
      link: '/settings'
    },
  ], trackAndRedirect = () => {
    trackData("View Retail Offers",{"source":"navbar"})
    navigate('retail/?utm_source=platform&utm_medium=navbar_btn&utm_campaign=employee_offer');
  };

  return (
    <div className="navbar">
      <div className="navbar-items">
        {activeMenu.map((item, index) => (
          <div key={index}>
            <NavbarLink to={item.link} key={index}>
              {item.icon}
              <span className="nav-page navbar-text">{item.name}</span>
            </NavbarLink>
          </div>
        ))}
      </div>
      {activeMode === 'employee' && userDetails?.has_offers &&
        <div onClick={() => trackAndRedirect()}>
          <NavbarLink key={'offers'} className='highlighted box box-anim'>
            <Badge dot>
              <GiftOutlined className='offer-icon' />
            </Badge>
            <span className="nav-page navbar-text">
              Offers
              <Tag className='offer-tag' >New</Tag>
            </span>
          </NavbarLink>
        </div>
      }
      <div className="footer">
        <ContactCard />
      </div>
    </div >
  );
};

export default Navbar;
