import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invitation_auto_accept_days: false,
};

const flags = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    setAutoInvitationDays: (state, action) => {
      state.invitation_auto_accept_days = action.payload.invitation_auto_accept_days;
    },
  },
});

export const flagActions = flags.actions;

export default flags.reducer;
