import { Col, Row, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../store/auth';
import { trackData } from '../../../utils/trackers';
import './_toggle.scss';

interface Props {
  toggle?: any;
  text?: any;
}

const EmployerEmployeeToggle: React.FC<Props> = ({ text, toggle }) => {
  const isEmployer = useSelector((state: any) => state.auth.roles)?.includes('employer'),
    activeMode = useSelector((state: any) => state.auth.activeMode),
    dispatch = useDispatch();

  const onChangeHandler = (checked: boolean) => {
    if (checked) {
      dispatch(authActions.changeActiveMode('employer'));
      trackData('Toggle Role', { 'Toggled To': 'employer' });
    } else {
      dispatch(authActions.changeActiveMode('employee'));
      trackData('Toggle Role', { 'Toggled To': 'employee' });
    }
  };

  if (!isEmployer) {
    return null;
  }

  return (
    <Row align="middle">
      <Col>
        <div className={`${text || 'header-strip-text'}`}>Employee</div>
      </Col>
      <Col className={`${toggle || 'toggle-switch'}`}>
        <Switch
          onChange={(checked: boolean) => {
            onChangeHandler(checked);
          }}
          checked={activeMode === 'employer'}
        />
      </Col>
      <Col>
        <div className={`${text || 'header-strip-text'}`}>Admin</div>
      </Col>
    </Row>
  );
};

export default EmployerEmployeeToggle;
