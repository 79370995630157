import { Col, Row } from 'antd';
import React from 'react';
import { mobileNumber } from '../../../utils/helper';
import './_contact-card.scss';
import { trackData } from '../../../utils/trackers';

interface Props {
  relationship_managers: any;
  activeMode: string;
}

const ContactCardWeb: React.FC<Props> = ({ relationship_managers, activeMode }) => {
  // const claimsHelplineNumber = '+919021323456'
  return (
    <React.Fragment>
      {(relationship_managers?.claim_manager?.full_name || relationship_managers?.policy_manager?.full_name) && (
        <div className="contact-card">
          <div className="description">For queries, please contact : </div>
          {activeMode === 'employer' && relationship_managers?.policy_manager?.full_name && (
            <>
              <div className="divider" />
              <Row className="relationship-manager-card" justify="space-between">
                <Col span={24}>
                  <div className="title">Relationship Manager</div>
                  <div className="details">{relationship_managers?.policy_manager?.full_name}</div>
                  <div className="details">
                    <a
                      className="clean-anchor"
                      target="_blank"
                      href={`tel:${mobileNumber(relationship_managers?.policy_manager?.phone)}`}
                      onClick={() => {
                        trackData('Call Policy Manager', {
                          'Claims Manager': relationship_managers?.policy_manager?.full_name,
                          'Contact': `${relationship_managers?.policy_manager?.phone}`,
                          'Location': 'Contact Card Web',
                        });
                      }}            
                    >
                      {mobileNumber(relationship_managers?.policy_manager?.phone)}
                    </a>
                  </div>
                  <div className="details">
                    <a
                      className="clean-anchor text-wrap"
                      target="_blank"
                      href={`mailto:${relationship_managers?.policy_manager?.email}`}
                      onClick={() => {
                        trackData('Email Policy Manager', {
                          'Claims Manager': relationship_managers?.policy_manager?.full_name,
                          'Contact': `${relationship_managers?.policy_manager?.email}`,
                          'Location': 'Contact Card Web',
                        });
                      }}            
                    >
                      {relationship_managers?.policy_manager?.email}
                    </a>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <div className="divider" />

          {relationship_managers?.claim_manager?.full_name && (
            <Row className="relationship-manager-card" justify="space-between">
              <Col span={24}>
                {activeMode === 'employer' && <>
                  <div className="title">For Claims:</div>
                </>
                }
                <div className="details">{relationship_managers?.claim_manager?.full_name}</div>
                <div className="details">
                  <a
                    className="clean-anchor"
                    target="_blank"
                    href={`tel:${mobileNumber(relationship_managers?.claim_manager?.phone)}`}
                    onClick={() => {
                      trackData('Call Policy Manager', {
                        'Claims Manager': relationship_managers?.claim_manager?.full_name,
                        'Contact': `${relationship_managers?.claim_manager?.phone}`,
                        'Location': 'Contact Card Web',
                      });
                    }}  
                  // href={`tel:${mobileNumber(claimsHelplineNumber)}`}
                  >
                    {mobileNumber(relationship_managers?.claim_manager?.phone)}
                    {/* {mobileNumber(claimsHelplineNumber)} */}
                  </a>
                </div>
                <div className="details">
                  <a
                    className="clean-anchor"
                    target="_blank"
                    href={`mailto:${relationship_managers?.claim_manager?.email}`}
                    onClick={() => {
                      trackData('Call Policy Manager', {
                        'Claims Manager': relationship_managers?.claim_manager?.full_name,
                        'Contact': `${relationship_managers?.claim_manager?.email}`,
                        'Location': 'Contact Card Web',
                      });
                    }} 
                  >
                    {relationship_managers?.claim_manager?.email}
                  </a>
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ContactCardWeb;
