import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import './_button.scss';

interface Props {
  onClick?: () => void;
  type: 'primary' | 'secondary' | 'tertiary';
  className?: string;
  children?: any;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
  size?: 'large'
}

const Button: React.FC<Props> = ({ onClick, className, children, loading, disabled, type, block = false, size }) => {
  return (
    <div className='retail-layout'>
      <button
        type="button"
        className={`${type} ${block ? 'full-width' : ''} ${className ? className : ''} ${size === 'large' ? "large" : ''} ${loading || disabled ? 'disabled' : ''} `}
        disabled={disabled || loading}
        onClick={onClick}
      >
        {children}&nbsp;
        {loading && <LoadingOutlined className="btn-loader" />}
      </button>
    </div>
  );
};

export default Button;
