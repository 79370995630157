import { createSlice } from '@reduxjs/toolkit';

interface errorState {
  id: number | null;
  message: number | string | null;
}

const initialState: errorState = {
  id: null,
  message: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.id = action.payload?.id;
      state.message = action.payload?.message;
    },
  },
});

export const errorActions = errorSlice.actions;
export default errorSlice.reducer;
