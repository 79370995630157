import api from '../api/api';
import { authActions } from '../store/auth';
import { errorHandler } from '../utils/helper';

const logout =
  () =>
  async (dispatch: any): Promise<void> => {
    api.profile
      .logout()
      .then((res) => {
        dispatch(authActions.logout());
      })
      .catch((err) => {
        errorHandler(err?.response?.data);
      });
  };

export const thunks = {
  logout,
};
