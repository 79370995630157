import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import './_button.scss';

interface Props {
  onClick?: () => void;
  className?: string;
  children?: any;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
  icon?: any;
  active? : boolean;
  style?: any;
}

const PrimaryButton: React.FC<Props> = ({ onClick, className, children, loading, disabled, block, icon }) => {
  return (
    <button
      type="button"
      className={`button-primary ${block ? 'block' : ''} ${className} ${loading || disabled ? 'disabled' : ''} `}
      disabled={disabled || loading}
      onClick={onClick}
    >
      <span>{children}&nbsp;</span>
      <span style={{ float: 'right' }}>{icon}</span>
      {loading && <LoadingOutlined className="btn-loader" />}
    </button>
  );
};

export const SecondaryButton: React.FC<Props> = ({ onClick, className, children, loading, disabled, block }) => {
  return (
    <button
      type="button"
      className={`button-secondary ${block ? 'block' : ''} ${className} ${loading || disabled ? 'disabled' : ''}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {children}&nbsp;
    </button>
  );
};

export const TertiaryButton: React.FC<Props> = ({ onClick, className, children, loading, disabled }) => {
  return (
    <button
      type="button"
      className={`button-tertiary ${className} ${loading || disabled ? 'disabled' : ''}`}
      disabled={loading}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const TabButton: React.FC<Props> = (props) => {
  return (
    <button className={`primary-text tab-button ${props.active ? 'active' : null} `} onClick={props.onClick} style={props.style} >
      {props.children}
    </button>
  );
};

// export const PrimaryButtonBlock: React.FC<Props> = ({ onClick, className, children, loading, disabled }) => {
//   return (
//     <button
//       type="button"
//       className={`button-primary block ${className} ${loading || disabled ? 'disabled' : ''}`}
//       disabled={disabled || loading}
//       onClick={onClick}
//     >
//       {children}
//     </button>
//   );
// };

export default PrimaryButton;
