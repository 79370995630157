import { Spin } from "antd";
import { useSelector } from "react-redux";

interface Props {
  loading?: boolean;
  screen?: string;
  children: any;
  isUnauth?: boolean;
}

const Loader: React.FC<Props> = ({ loading, screen, children, isUnauth }) => {
  const permissions = useSelector((state: any) => state.auth.permissions);

  if (loading) {
    return (
      <div className="loader-div">
        <div className="loader">
          <Spin size="large"></Spin>
        </div>
      </div>
    );
  }

  // if ((screen && !permissions?.[screen]?.includes(VIEW_PERMISSION)) || (isUnauth)) {
  //   return <Unauthorized />
  // }

  return children;
}

export default Loader;
