import React from 'react';
import { toTitleCase } from '../../../utils/helper';
import './_tag.scss';

interface Props {
  children: any;
  type: string;
}

const SUCCESS = ['success', 'accepted', 'active', 'settled', 'covered'];

const FAIL = ['fail', 'rejected', 'cancelled', 'expired', 'inactive', 'warning', 'rejected', 'withdrawn', 'not_covered'];

const PENDING = [
  'pending',
  'processing',
  'waiting',
  'unpaid',
  'unconfirmed',
  'unverified',
  'unapproved',
  'progress',
  'in-progress',
  'in progress',
  'in process',
  'in-process',
  'edition in process',
  'document pending',
  'document received',
  'document submitted',
  'query unresolved',
  'query resolved',
  'approved',
];

const INVITED = ['invited', 'inviting', 'invites'];

const ADDITION_IN_PROCESS = ['addition', 'addition in process'];
const DELETION_IN_PROCESS = ['deletion', 'deletion in process'];

const Tag: React.FC<Props> = ({ children, type }) => {
  let className = '';
  let tagName = '';
  let renderType = toTitleCase(type)?.toLowerCase();
  if (SUCCESS.includes(renderType)) {
    className = 'success';
  } else {
    if (FAIL.includes(renderType)) {
      className = 'fail';
    } else {
      if (PENDING.includes(renderType)) {
        className = 'pending';
        // tagName = 'In Process';
        tagName = renderType;
      } else {
        if (INVITED.includes(renderType)) {
          className = 'invited';
          // tagName = 'INVITED';
          tagName = renderType;
        } else if (ADDITION_IN_PROCESS.includes(renderType)) {
          className = 'addition';
          tagName = 'Addition In Process';
        } else if (DELETION_IN_PROCESS.includes(renderType)) {
          className = 'deletion';
          tagName = 'Deletion In Process';
        } else {
          className = 'pending';
        }
      }
    }
  }

  return (
    <div className={`tag ${className != '' ? className : 'pending'}`}>
      {tagName !== '' ? tagName : toTitleCase(children)}
    </div>
  );
};

export default Tag;
