import { useState } from "react";
import { Outlet } from "react-router-dom";

export interface LoginOutletContext {
  email: string,
  phone: string,
  sso: boolean,
  errorMessage: string;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>,
  setPhone: React.Dispatch<React.SetStateAction<string | undefined>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
  setSso: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  resetError: VoidFunction;
  ssoURL: string;
  SetSsoURL: React.Dispatch<React.SetStateAction<string | undefined>>,
  resetSSO: VoidFunction;
}

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [sso, setSso] = useState<boolean>();
  const [ssoURL, SetSsoURL] = useState<string>();

  const resetSSO = () => {
    setSso(undefined)
    SetSsoURL(undefined)
  };

  return <Outlet context={{
    email,
    phone,
    sso,
    setEmail,
    setPhone,
    setSso,
    ssoURL,
    SetSsoURL,
    resetSSO
  }} />;
};

export default Login;
