import { Pagination } from 'antd';
import { useEffect, useState } from 'react';
import './_table.scss';

interface Props {
  total: number | undefined;
  onPageChange: any;
  urlOffset?: number;
  urlLimit?: number;
}

const PaginationComponent: React.FC<Props> = ({ total, onPageChange, urlOffset, urlLimit }) => {
  const [currentPage, setCurrentPage] = useState({ pageNumber: 1, pageSize: 10 });

  const pageChangeHandler = (page: number, pageSize: number) => {
    onPageChange({ limit: pageSize, offset: (page - 1) * pageSize });
    setCurrentPage({ pageNumber: page, pageSize: pageSize });
  };

  const pageSizeChangeHandler = (current: number, pageSize: number) => {
    onPageChange({ limit: pageSize, offset: (current - 1) * pageSize });
    setCurrentPage({ pageNumber: current, pageSize: pageSize });
  };

  useEffect(() => {
    if (urlOffset === 0 && urlLimit) {
      setCurrentPage({ pageNumber: 1, pageSize: urlLimit });
    }
  }, [urlOffset]);

  useEffect(() => {
    if (urlLimit) {
      setCurrentPage({ pageNumber: 1, pageSize: urlLimit });
      onPageChange({ limit: urlLimit, offset: 0 });
    }
  }, [urlLimit]);

  let pageSizeOptions = [10, 20, 30, 40, 50, 100] as number[];

  return (
    <Pagination
      className="pagination"
      size="small"
      current={currentPage.pageNumber}
      pageSizeOptions={
        total
          ? Array.from(new Set(pageSizeOptions)).filter(function (item: any) {
              return item <= pageSizeOptions[pageSizeOptions.length - 1];
            })
          : []
      }
      locale={{ items_per_page: '' }}
      pageSize={currentPage.pageSize}
      total={total}
      showSizeChanger
      onChange={(page, pageSize) => {
        pageChangeHandler(page, pageSize);
      }}
      onShowSizeChange={(current, size) => {
        pageSizeChangeHandler(current, size);
      }}
    />
  );
};

export default PaginationComponent;
